import gsap from "gsap";
import Flickity from "flickity";
import * as PIXI from "pixi.js";
import { HTMLText } from "@pixi/text-html";
import LazyLoad from "vanilla-lazyload";
import Isotope from "isotope-layout";
import Zoomage from "zoomage.js";
const HTML = document.querySelector("html");
class App {
  constructor() {
    this.currentTpl = -1;
    this.lazyload = new LazyLoad({
      unobserve_entered: true,
      elements_selector: ".js-lazy",
      threshold: window.innerHeight * 0.8,
      callback_enter: ($el) => {
        if ($el.classList.contains("js-lazy-error-placeholder")) {
          $el.lazyLoadPlaceholder = $el.src;
        } else if ($el.dataset.src == void 0) {
          const figure = $el.closest("figure");
          if (figure)
            figure.classList.add("img-is-loaded");
        }
      },
      callback_loaded: (el) => {
        const figure = el.closest("figure");
        if (figure)
          figure.classList.add("img-is-loaded");
      },
      callback_error: ($el) => {
        if ($el.lazyLoadPlaceholder) {
          $el.src = $el.lazyLoadPlaceholder;
        }
      }
    });
    const panels = document.querySelectorAll(".panel");
    if (panels) {
      panels.forEach((panel) => {
        this.buildUI(panel);
      });
    }
    const submits = document.querySelectorAll(".submit");
    if (submits) {
      submits.forEach((submit) => {
        submit.addEventListener("click", (e) => {
          e.preventDefault();
          HTML.classList.add("loading");
          const parent = submit.closest(".tabs-item");
          this.buildMedias(parent.querySelector(".formats"));
        });
      });
    }
    const backs = document.querySelectorAll(".btn-back");
    if (backs) {
      backs.forEach((back) => {
        back.addEventListener("click", (e) => {
          e.preventDefault();
          HTML.classList.remove("show-render");
          const render = document.querySelector(".render:not(.init)");
          if (render) {
            gsap.to(render, { autoAlpha: 0, duration: 0.3, onComplete: () => render.remove() });
            gsap.to(document.querySelector(".main"), { autoAlpha: 1, duration: 0.4, delay: 0.3 });
          }
        });
      });
    }
    const overlay = document.querySelector(".overlay");
    if (overlay) {
      overlay.addEventListener("click", (e) => {
        HTML.classList.remove("show-overlay", "show-form");
      });
    }
    this.tabs();
    this.slider();
    this.form();
    this.isotope();
    this.step();
  }
  isotope() {
    const grids = document.querySelectorAll(".grid-isotope");
    if (grids) {
      grids.forEach((grid) => {
        var iso = new Isotope(grid, {
          itemSelector: '[class*="grid-col-"]',
          masonry: {
            columnWidth: ".grid-sizer"
          }
        });
      });
    }
  }
  step() {
    const elts = document.querySelectorAll(".step");
    if (elts) {
      elts.forEach((el) => {
        let current = 0;
        const steps = el.querySelectorAll(".step-content");
        const triggers = el.querySelectorAll("[data-step]");
        if (steps && triggers) {
          triggers.forEach((trigger) => {
            trigger.addEventListener("click", (e) => {
              e.preventDefault();
              if (steps[trigger.dataset.step]) {
                if (trigger.dataset.click) {
                  const target = document.querySelector(trigger.dataset.click);
                  if (target) {
                    target.dispatchEvent(new Event("click"));
                  }
                }
                gsap.to(steps[current], {
                  x: "-100%",
                  duration: 0.5,
                  ease: "circ.inOut"
                });
                gsap.fromTo(steps[trigger.dataset.step], {
                  x: "100%",
                  autoAlpha: 1
                }, {
                  x: "0%",
                  duration: 0.5,
                  ease: "circ.inOut"
                });
              }
            });
          });
        }
      });
    }
  }
  tabs() {
    const _ = this;
    const elts = document.querySelectorAll(".tabs");
    const tuto = document.querySelector(".tuto");
    if (elts) {
      elts.forEach((el) => {
        const triggers = el.querySelectorAll(".tabs-header a");
        const container = el.querySelector(".tabs-container");
        const items = el.querySelectorAll(".tabs-item");
        let current = -1;
        if (triggers && container && items) {
          let goto = function(index) {
            if (tuto) {
              gsap.to(tuto, { autoAlpha: 0, duration: 0.3 });
            }
            if (index == current)
              return;
            let delay = 0;
            if (current >= 0) {
              gsap.to(items[current], {
                y: "-2rem",
                duration: 0.5,
                autoAlpha: 0
              });
              triggers[current].classList.remove("active");
              delay = 0.2;
            }
            gsap.fromTo(items[index], { y: "2rem", autoAlpha: 0 }, { autoAlpha: 1, duration: 0.5, y: "0rem", delay });
            triggers[index].classList.add("active");
            const slides = items[index].querySelectorAll(".slider .item");
            if (slides) {
              slides[0].dispatchEvent(new Event("autoplay"));
            }
            current = index;
            _.currentTpl = current;
          };
          triggers.forEach((trigger, i) => {
            trigger.addEventListener("click", (e) => {
              e.preventDefault();
              goto(i);
            });
          });
        }
      });
    }
  }
  slider() {
    const sliders = document.querySelectorAll(".slider");
    if (sliders) {
      sliders.forEach((slider) => {
        const nav = slider.querySelectorAll(".slider-nav a");
        const items = slider.querySelectorAll(".item");
        let current = 0;
        items.forEach((item) => {
          const v = item.querySelector("video");
          if (v && v.dataset.autoplay == "true") {
            item.addEventListener("autoplay", () => {
              v.play();
            });
            item.addEventListener("stopautoplay", () => {
              v.pause();
            });
          }
        });
        const flkty = new Flickity(slider, {
          prevNextButtons: false,
          watchCSS: true,
          cellSelector: ".item",
          on: {
            ready: () => {
              if (nav) {
                nav[current].classList.add("active");
              }
            },
            change: (index) => {
              items[index].dispatchEvent(new Event("autoplay"));
            },
            select: (index) => {
              if (nav) {
                nav[current].classList.remove("active");
                nav[index].classList.add("active");
                items[current].dispatchEvent(new Event("stopautoplay"));
                current = index;
              }
            }
          }
        });
        if (nav) {
          nav.forEach((trigger, i) => {
            trigger.addEventListener("click", (e) => {
              e.preventDefault();
              flkty.select(i);
            });
          });
        }
      });
    }
  }
  async buildUI(el) {
    const templates = settings.templates;
    if (templates) {
      for (let i in templates) {
        const tpl = templates[i];
        const includes = tpl.includes;
        if (includes) {
          for (let j in includes) {
            const include = includes[j];
            if (include.includes("woff2")) {
              const names = include.match(/[ \w-]+\./);
              const fontFamily = names.length > 0 ? names[0].replaceAll(".", "") : `custom font tmp`;
              const font = new FontFace(fontFamily, `url(${include}) format("woff2")`);
              const loadedFont = await font.load();
              document.fonts.add(loadedFont);
            }
          }
        }
      }
    }
    const formats = el.querySelectorAll(".format");
    if (formats) {
      formats.forEach((format) => {
        const attr = JSON.parse(format.dataset.attr);
        this.buildFormat(format, attr, el);
      });
    }
    const elts = el.querySelectorAll(".features");
    if (elts) {
      elts.forEach((c) => {
        const features = c.querySelectorAll(".feature");
        if (features) {
          features.forEach((feature, i) => {
            const f = this.buildFeature(feature, el);
            if (i == 0 && f.click) {
              feature.dispatchEvent(new Event("click"));
            }
          });
        }
      });
    }
  }
  async buildFormat(el, attr, parent) {
    const canvasContainer = el.querySelector(".canvas");
    if (canvasContainer) {
      canvasContainer.innerHTML = "";
      const container = document.createElement("div");
      container.classList.add("zones");
      container.style.width = `${attr.width}px`;
      container.style.height = `${attr.height}px`;
      container.style.transformOrigin = "top left";
      canvasContainer.append(container);
      window.addEventListener("resize", (e) => {
        const scale = canvasContainer.offsetWidth / attr.width;
        container.style.transform = `scale(${scale})`;
      });
      window.dispatchEvent(new Event("resize"));
      if (attr.zones && attr.zones.length > 0) {
        for (let i = 0; i < attr.zones.length; i++) {
          const zone = attr.zones[i];
          const ex = "Votre texte";
          let { fontFamily } = zone;
          const textarea = document.createElement("div");
          textarea.classList.add("textarea");
          textarea.style.position = "absolute";
          textarea.style.fontFamily = fontFamily;
          textarea.style.background = "transparent";
          textarea.style.width = `${attr.width * zone.width}px`;
          textarea.style.minHeight = `${zone.lineHeight * zone.fontSize}px`;
          textarea.style.left = `${zone.x * 100}%`;
          textarea.style.transform = zone.valign == "middle" ? "translate3d(-50%, -50%, 0)" : "translate3d(-50%, 0%, 0)";
          textarea.style.fontSize = `${zone.fontSize ? Number.parseFloat(zone.fontSize) : 80}px`;
          textarea.style.textTransform = zone.textTransform;
          textarea.style.letterSpacing = zone.letterSpacing;
          textarea.style.textAlign = zone.align;
          textarea.style.fontWeight = zone.fontWeight;
          textarea.style.lineHeight = zone.lineHeight;
          textarea.style.color = zone.color.replace("0x", "#");
          textarea.style.borderColor = zone.color.replace("0x", "#");
          textarea.style.borderRadius = "35px";
          textarea.setAttribute("contenteditable", "true");
          textarea.setAttribute("data-zone", "true");
          if (zone.valign == "bottom") {
            textarea.style.bottom = `${zone.y * 100}%`;
          } else {
            textarea.style.top = `${zone.y * 100}%`;
          }
          container.append(textarea);
          textarea.addEventListener("keyup", (e) => {
            const textareas = parent.querySelectorAll(".format .textarea");
            if (textareas) {
              textareas.forEach((t) => {
                if (t != textarea) {
                  t.innerHTML = textarea.innerHTML;
                }
              });
            }
          });
          textarea.addEventListener("focus", (e) => {
            const textareas = parent.querySelectorAll(".format .textarea");
            if (textarea.innerHTML.replace(/(<([^>]+)>)/gi, "") == "") {
              textareas.forEach((t) => t.classList.add("focus"));
            }
          });
          textarea.addEventListener("blur", (e) => {
            const textareas = parent.querySelectorAll(".format .textarea");
            if (textarea.innerHTML.replace(/(<([^>]+)>)/gi, "") == "") {
              textareas.forEach((t) => {
                if (t.innerHTML == "") {
                  t.classList.remove("focus");
                }
              });
            }
          });
        }
      }
    }
  }
  buildFeature(el, parent) {
    let click = true;
    let attrs = JSON.parse(el.dataset.options);
    for (let i in attrs) {
      const attr = attrs[i];
      if (attr.target == "upload") {
        click = false;
        const zoomageEl = document.createElement("div");
        zoomageEl.classList.add("zoomage");
        gsap.set(zoomageEl, {
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          overflow: "hidden"
        });
        const canvas = parent.querySelectorAll(".format .canvas");
        if (canvas) {
          canvas.forEach((c) => {
            c.closest(".format").classList.add("format-upload");
            c.parentNode.prepend(zoomageEl);
          });
        }
        const file = document.createElement("input");
        file.type = "file";
        file.accept = "image/*";
        el.addEventListener("click", (e) => {
          e.preventDefault();
          file.click();
        });
        file.addEventListener("change", (e) => {
          const file2 = e.target.files[0];
          let fileReader = new FileReader();
          fileReader.readAsDataURL(file2);
          fileReader.onload = function() {
            zoomageEl.innerHTML = "";
            const oldControl = zoomageEl.parentNode.querySelector(".control-zoomage");
            if (oldControl)
              oldControl.remove();
            const z = new Zoomage({
              container: zoomageEl,
              enableDesktop: true,
              enableGestureRotate: true,
              minZoom: 0.05
            });
            z.load(fileReader.result);
            zoomageEl.zoomage = z;
            const control = document.createElement("div");
            control.classList.add("control-zoomage");
            zoomageEl.parentNode.append(control);
            const triggerZoomMore = document.createElement("a");
            triggerZoomMore.classList.add("zoom-more");
            triggerZoomMore.addEventListener("click", (e2) => {
              e2.preventDefault();
              z.zoom(0.1);
            });
            control.append(triggerZoomMore);
            const triggerZoomLess = document.createElement("a");
            triggerZoomLess.classList.add("zoom-less");
            triggerZoomLess.addEventListener("click", (e2) => {
              e2.preventDefault();
              z.zoom(-0.1);
            });
            control.append(triggerZoomLess);
          };
        });
      }
    }
    el.addEventListener("click", (e) => {
      attrs = JSON.parse(el.dataset.options);
      e.preventDefault();
      const activesElt = el.closest(".features").querySelectorAll(".active");
      if (activesElt) {
        activesElt.forEach((active) => active.classList.remove("active"));
      }
      el.classList.add("active");
      for (let i in attrs) {
        const attr = attrs[i];
        if (attr.target == "selectedText") {
        } else if (attr.target == "upload") {
          el.classList.remove("active");
        } else if (attr.target == "dependency") {
          const v = attr.values;
          const targets = parent.querySelectorAll(`[data-key='${attr.dependency}']`);
          if (targets) {
            targets.forEach((target, i2) => {
              const newEltValues = v[i2];
              const options = JSON.parse(target.dataset.options);
              if (newEltValues && options) {
                for (let o in options) {
                  options[o].values = newEltValues[o];
                }
              }
              target.setAttribute("data-options", JSON.stringify(options));
            });
            const active = parent.querySelector(`[data-key='${attr.dependency}'].active`);
            if (active)
              active.dispatchEvent(new Event("click"));
          }
        } else {
          const formats = parent.querySelectorAll(".format");
          if (formats) {
            formats.forEach((format, i2) => {
              let value = "";
              if (typeof attr.values == "object") {
                value = attr.values[i2] ? attr.values[i2] : attr.values[0];
              } else {
                value = attr.values;
              }
              const targets = format.querySelectorAll(`[data-${attr.target}]`);
              if (targets) {
                targets.forEach((target) => {
                  if (attr.property) {
                    target.setAttribute(attr.property, value);
                    if (value.indexOf("mp4") >= 0) {
                      gsap.set(target, { autoAlpha: 0 });
                      target.addEventListener("canplay", (e2) => {
                        gsap.to(target, { autoAlpha: 1, duration: 0.3 });
                      });
                      target.play();
                    }
                  } else {
                    target.style[attr.style] = value;
                  }
                });
              }
            });
          }
        }
      }
    });
    return { "click": click };
  }
  async buildMedias(el) {
    if (el) {
      const data = [];
      const formats = el.querySelectorAll(".format");
      if (formats) {
        await Promise.all(Array.from(formats).map(async (format) => {
          const attr = JSON.parse(format.dataset.attr);
          const photo = await this.bgUploaded(format, attr);
          const img = await this.screenshot(format.querySelector(".zones"), attr);
          const bg = format.querySelector("[data-bg]").getAttribute("src");
          data.push({
            slug,
            photo,
            img,
            bg,
            width: attr.width,
            height: attr.height
          });
        }));
        const body = new FormData();
        body.append("data", JSON.stringify(data));
        body.append("action", "build");
        fetch("./inc/.ajax.php", {
          method: "POST",
          body
        }).then(function(res) {
          return res.json();
        }).then((data2) => {
          if (data2.statut) {
            const medias = data2.medias;
            HTML.classList.remove("loading");
            HTML.classList.add("show-render");
            const panel = document.querySelector(".render").cloneNode(true);
            panel.classList.remove("init");
            document.body.append(panel);
            if (panel) {
              panel.classList.add("show");
              const slider = panel.querySelector(".slider-render");
              const base = panel.querySelector(".base");
              const flkty = new Flickity(slider, {
                prevNextButtons: false,
                cellSelector: ".item",
                watchCSS: true
              });
              for (let i in medias) {
                const media = medias[i];
                const slide = base.cloneNode(true);
                const containerMedia = slide.querySelector(".media");
                slide.classList.remove("opacity-0", "invisible", "base", "absolute");
                let el2 = document.createElement("img");
                if (media.indexOf(".mp4") >= 0) {
                  el2 = document.createElement("video");
                  el2.muted = true;
                  el2.loop = true;
                  el2.autoplay = true;
                  el2.playsinline = true;
                  el2.play();
                }
                el2.classList.add("max-w-[90%]", "max-h-[90%]", "mx-auto", "js-lazy");
                el2.src = media;
                containerMedia.append(el2);
                const btn = document.createElement("a");
                btn.classList.add("btn", "mt-4");
                btn.innerHTML = "<span>Enregistrer</span>";
                btn.addEventListener("click", async (e) => {
                  e.preventDefault();
                  const blob = await fetch(media).then((res) => res.blob());
                  const name = media.substring(media.lastIndexOf("/") + 1);
                  await this.shareOrDownload(blob, name, "", "");
                });
                containerMedia.append(btn);
                const form = document.querySelector(".form-mail form");
                if (form) {
                  form.querySelector('input[name="medias"]').value = JSON.stringify(medias);
                  const triggerMail = panel.querySelector(".trigger-mail");
                  if (triggerMail) {
                    triggerMail.addEventListener("click", (e) => {
                      HTML.classList.add("show-overlay", "show-form");
                      form.querySelector("input[name='email']").focus();
                    });
                  }
                }
                if (window.innerWidth < 1024) {
                  flkty.append(slide);
                } else {
                  slider.append(slide);
                }
              }
              gsap.to(document.querySelector(".main"), { autoAlpha: 0, duration: 0.4 });
              gsap.to(panel, { autoAlpha: 1, duration: 0.4, delay: 0.15 });
              gsap.from(slider, { autoAlpha: 0, x: "5rem", duration: 0.4, ease: "expo.out", delay: 0.25 });
            }
          }
        });
      }
    }
  }
  async bgUploaded(el, attr) {
    const zoomage = el.querySelector(".zoomage canvas");
    if (zoomage) {
      const photo = zoomage.toDataURL("image/png", 1);
      const app = new PIXI.Application({
        width: attr.width,
        height: attr.height,
        backgroundColor: 0,
        backgroundAlpha: 0,
        antialias: true
      });
      document.body.append(app.view);
      const container = new PIXI.Container();
      app.stage.addChild(container);
      container.x = 0;
      container.y = 0;
      container.width = app.renderer.width;
      container.height = app.renderer.height;
      const square = new PIXI.Graphics();
      square.beginFill(0);
      square.drawRect(0, 0, app.renderer.width, app.renderer.height);
      square.endFill();
      square.cacheAsBitmap = true;
      container.addChild(square);
      const z = zoomage.closest(".zoomage").zoomage;
      const boundParent = zoomage.parentNode.getBoundingClientRect();
      const boundPhoto = zoomage.getBoundingClientRect();
      const wPhoto = gsap.getProperty(zoomage, "width");
      const hPhoto = gsap.getProperty(zoomage, "height");
      const ratioW = wPhoto * z.zoomD / boundParent.width;
      const ratioH = hPhoto * z.zoomD / boundParent.height;
      const sheet = await PIXI.Assets.load(photo);
      const sprite = new PIXI.Sprite(sheet);
      sprite.anchor.set(0.5);
      sprite.width = ratioW * app.renderer.width;
      sprite.height = ratioH * app.renderer.height;
      sprite.x = (boundPhoto.x - boundParent.x) / boundPhoto.width * sprite.width + sprite.width * 0.5;
      sprite.y = (boundPhoto.y - boundParent.y) / boundPhoto.height * sprite.height + sprite.height * 0.5;
      sprite.angle = gsap.getProperty(zoomage, "rotate");
      container.addChild(sprite);
      const mask = new PIXI.Graphics();
      mask.beginFill(6684672);
      mask.drawRect(0, 0, app.renderer.width, app.renderer.height);
      mask.endFill();
      sprite.mask = mask;
      const base64 = await app.renderer.extract.base64(container, "image/png", 1);
      app.destroy(true);
      return base64;
    }
    return false;
  }
  async screenshot(el, attr) {
    const canvas = document.createElement("canvas");
    const clone = el.cloneNode(true);
    clone.style.transform = "";
    clone.style.position = "absolute";
    clone.style.opacity = 0;
    clone.style.pointerEvents = "none";
    document.body.append(clone);
    const boundP = clone.getBoundingClientRect();
    const app = new PIXI.Application({
      view: canvas,
      width: attr.width,
      height: attr.height,
      backgroundColor: 0,
      backgroundAlpha: 0,
      antialias: true
    });
    const container = new PIXI.Container();
    app.stage.addChild(container);
    container.x = 0;
    container.y = 0;
    container.width = app.renderer.width;
    container.height = app.renderer.height;
    const zones = clone.querySelectorAll(".textarea");
    if (zones) {
      await Promise.all(Array.from(zones).map(async (zone, i) => {
        const param = attr.zones[i];
        const bound = zone.getBoundingClientRect();
        const eltsDel = zone.querySelectorAll("br[data-mce-bogus]");
        if (eltsDel) {
          eltsDel.forEach((eldel) => eldel.remove());
        }
        const content = zone.innerHTML.replaceAll("<p>", "").replaceAll("</p>", "<br>");
        const text = new HTMLText(content, {
          fontFamily: zone.style.fontFamily,
          fontSize: zone.style.fontSize ? Number.parseFloat(zone.style.fontSize) : 80,
          align: zone.style.textAlign,
          fontWeight: Number.parseFloat(zone.style.fontWeight),
          lineHeight: Number.parseFloat(zone.style.lineHeight) * Number.parseFloat(zone.style.fontSize),
          textTransform: zone.style.textTransform,
          letterSpacing: zone.style.letterSpacing,
          breakWords: true,
          wordWrap: true,
          wordWrapWidth: app.renderer.width * param.width,
          fill: zone.style.color,
          backgroundColor: 16773120
        });
        if (zone.style.textAlign == "right") {
          text.x = bound.x - boundP.x + bound.width;
          text.anchor.x = 1;
        } else if (zone.style.textAlign == "left") {
          text.x = bound.x - boundP.x;
          text.anchor.x = 0;
        } else {
          text.x = bound.x - boundP.x + bound.width / 2;
          text.anchor.x = 0.5;
        }
        text.y = bound.y - boundP.y + bound.height / 2;
        text.anchor.y = 0.5;
        const includes = settings.templates[this.currentTpl].includes;
        for (let j in includes) {
          const include = includes[j];
          if (include.indexOf(zone.style.fontFamily) >= 0) {
            await text.style.loadFont(include, {
              family: zone.style.fontFamily
            });
            ;
          }
        }
        container.addChild(text);
        await this.sleep();
      }));
      await this.waitFor(1e3);
      const base64 = app.renderer.view.toDataURL("image/png", 1);
      app.destroy(true);
      clone.remove();
      return base64;
    }
  }
  form() {
    const form = document.querySelector(".form-mail form");
    if (form) {
      form.addEventListener("submit", (e) => {
        e.preventDefault();
        const body = new FormData(e.target);
        body.append("action", "sendMail");
        HTML.classList.add("loading");
        fetch("./inc/.ajax.php", {
          method: "POST",
          body
        }).then(function(res) {
          return res.json();
        }).then((data) => {
          if (data.msg) {
            const msg = document.createElement("div");
            msg.classList.add("msg");
            msg.innerHTML = data.msg;
            document.body.append(msg);
            gsap.to(msg, { duration: 0.3, autoAlpha: 1, onComplete: () => {
              setTimeout(() => {
                HTML.classList.remove("loading");
                if (data.statut) {
                  HTML.classList.remove("show-overlay", "show-form");
                }
                gsap.to(msg, { autoAlpha: 0, duration: 0.3, onComplete: () => {
                  msg.remove();
                } });
              }, 3e3);
            } });
          }
        });
        return false;
      });
    }
  }
  async sleep() {
    return new Promise(requestAnimationFrame);
  }
  async waitFor(ms) {
    return new Promise((r) => setTimeout(r, ms));
  }
  async shareOrDownload(blob, fileName, title, text) {
    const webShareSupported = "canShare" in navigator;
    if (webShareSupported) {
      const data = {
        files: [
          new File([blob], fileName, {
            type: blob.type
          })
        ],
        title,
        text
      };
      if (navigator.canShare(data)) {
        try {
          await navigator.share(data);
        } catch (err) {
          if (err.name !== "AbortError") {
            console.error(err.name, err.message);
          }
        } finally {
          return;
        }
      }
    }
    const a = document.createElement("a");
    a.download = fileName;
    a.style.display = "none";
    a.href = URL.createObjectURL(blob);
    a.addEventListener("click", () => {
      setTimeout(() => {
        URL.revokeObjectURL(a.href);
        a.remove();
      }, 1e3);
    });
    document.body.append(a);
    a.click();
  }
}
window.addEventListener("load", () => {
  new App();
});


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
